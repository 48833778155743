import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TicketsPage = () => (
  <Layout pageInfo={{ pageName: "tickets" }}>
    <SEO title="Tickets" />
    <h3>[stay tuned...]</h3>
  </Layout>
)

export default TicketsPage
